import * as React from "react"
import {ConfigProvider} from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import 'moment/locale/ru';
import {Layout} from 'antd';

const {Content} = Layout;

const AppLayout = ({children}) => {
    return (
        <ConfigProvider locale={ruRU}>
            <Layout css={{minHeight: "100vh"}}>
                <Content>
                    {children}
                </Content>
            </Layout>
        </ConfigProvider>
    )
}

export default AppLayout
